import request from '@/utils/request';
import { envInfo } from '@/constants/envInfo';

/**
 * 新增拓扑
 * @param params
 */
export function createTopo(params) {
  return request({
    url: envInfo.bgApp.bmsPath + '/topo/createTopo',
    method: 'post',
    data: params,
  });
}

/**
 * 修改拓扑
 * @param params
 */
export function editTopo(params) {
  return request({
    url: envInfo.bgApp.bmsPath + '/topo/editTopo',
    method: 'post',
    data: params,
  });
}

/**
 * 获取列表
 * @param params
 */
export function findTopo(params) {
  return request({
    url: envInfo.bgApp.bmsPath + '/topo/findTopo',
    method: 'get',
    params,
  });
}

/**
 * 获取拓扑图详情
 * @param params
 */
export function findTopoDetail(topoId) {
  return request({
    url: envInfo.bgApp.bmsPath + '/topo/findTopoDetail/' + topoId,
    method: 'get',
  });
}

/**
 * 删除拓扑图
 * @param params
 */
export function deleteTopo(topoId) {
  return request({
    url: envInfo.bgApp.bmsPath + '/topo/deleteTopo/' + topoId,
    method: 'delete',
  });
}

/**
 * 发布拓扑
 * @param params
 */
export function publishTopo(params) {
  return request({
    url: envInfo.bgApp.bmsPath + '/topo/publishTopo',
    method: 'post',
    data: params,
  });
}

/**
 * 获取拓扑发布记录
 * @param params
 */
export function findPublishTopo(topoId) {
  return request({
    url: envInfo.bgApp.bmsPath + '/topo/findPublishTopo/' + topoId,
    method: 'get',
  });
}

/**
 * 获取详情
 * @param params
 */
export function findDeviceDataInfoBatch(params) {
  return request({
    url: envInfo.bgApp.bmsRviewPath + '/topology/findDeviceDataInfoBatch',
    method: 'post',
    data: params,
  });
}
