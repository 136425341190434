<template>
  <div class="topo-download-dialog">
    <el-dialog
      :close-on-click-modal="false"
      width="600px"
      :visible.sync="show"
      :before-close="dialogCloseHandler"
      append-to-body
    >
      <template slot="title"> <title-icon />导出</template>
      <div class="topo-select-container">
        <span
          :class="[
            'single-select',
            item.key === downloadType && 'single-select-active',
          ]"
          v-for="(item, index) in selectOptions"
          :key="index"
          @click="downloadType = item.key"
        >
          <span class="left-part">
            <img
              :src="
                require(`@/assets/images/topology/topo_${item.key}_icon.png`)
              "
              alt=""
            />
            <span class="select-label">
              {{ item.label }}
            </span>
          </span>
          <span class="right-circle" v-if="item.key !== downloadType"></span>
          <i class="el-icon-success" v-else></i>
        </span>
      </div>
      <div slot="footer" style="text-align: center">
        <el-button
          type="primary"
          @click="exportHandler"
          v-loading="exportLoading"
          >导 出</el-button
        >
        <el-button @click="dialogCloseHandler">取 消</el-button>
      </div>
    </el-dialog>
    <a id="aBase64Url" download style="display: none">
      <img src="" id="base64Url" />
    </a>
  </div>
</template>

<script>
import html2Canvas from "html2canvas";
import JsPDF from "jspdf";
export default {
  name: "topoDownloadDialog",
  props: {
    show: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      exportLoading: false,
      downloadType: "pdf",
      selectOptions: [
        {
          label: "PDF",
          key: "pdf",
        },
        {
          label: "JPG",
          key: "jpg",
        },
        {
          label: "PNG",
          key: "png",
        },
      ],
    };
  },
  methods: {
    dialogCloseHandler() {
      this.$emit("close");
    },
    exportStart() {
      try {
        const realHeight = $(".theme-default")[0].clientHeight;
        const realWidth = $(".theme-default")[0].clientWidth;
        const scrollTop = $(".jsmind-inner")[0].scrollTop;
        const scrollLeft = $(".jsmind-inner")[0].scrollLeft;
        $("#graphContainer")[0].style.height = `${realHeight}px`;
        $("#graphContainer")[0].style.width = `${realWidth}px`;
        $(".jsmind-inner")[0].scrollTop = 0;
        $(".jsmind-inner")[0].scrollLeft = 0;
        return { scrollTop, scrollLeft };
      } catch (error) {
        console.log("设置导出节点高度失败：", error);
      }
    },
    exportEnd({ scrollTop, scrollLeft }) {
      try {
        $("#graphContainer")[0].style.height = `100%`;
        $("#graphContainer")[0].style.width = `100%`;
        $(".jsmind-inner")[0].scrollTop = scrollTop;
        $(".jsmind-inner")[0].scrollLeft = scrollLeft;
      } catch (error) {
        console.log("设置导出节点高度失败：", error);
      }
    },
    exportHandler() {
      if (this.exportLoading) return;
      this.exportLoading = true;
      const scrollMap = this.exportStart();
      html2Canvas(document.querySelector("#graphContainer"))
        .then((canvas) => {
          let imageURL = canvas.toDataURL("image/png"); //canvas转base64图片
          console.log("downloadType", this.downloadType);
          switch (this.downloadType) {
            case "pdf":
              const contentWidth = canvas.width;
              const contentHeight = canvas.height;
              // 根据A4纸的大小，计算出dom相应比例的尺寸
              const pageHeight = (contentWidth / 592.28) * 841.89;
              let leftHeight = contentHeight;
              let position = 0;
              const imgWidth = 595.28;
              // 根据a4比例计算出需要分割的实际dom位置
              const imgHeight = (592.28 / contentWidth) * contentHeight;
              // canvas绘图生成image数据，1.0是质量参数
              const pageData = canvas.toDataURL("image/jpeg", 1.0);
              // a4大小
              const PDF = new JsPDF("", "pt", "a4");
              // 当内容达到a4纸的高度时，分割，将一整块画幅分割出一页页的a4大小，导出pdf
              if (leftHeight < pageHeight) {
                PDF.addImage(pageData, "JPEG", 0, 0, imgWidth, imgHeight);
              } else {
                while (leftHeight > 0) {
                  PDF.addImage(
                    pageData,
                    "JPEG",
                    0,
                    position,
                    imgWidth,
                    imgHeight
                  );
                  leftHeight -= pageHeight;
                  position -= 841.89;
                  if (leftHeight > 0) {
                    PDF.addPage();
                  }
                }
              }
              // 导出
              PDF.save("topo.pdf");
              break;
            case "png":
              document.getElementById("aBase64Url").href = imageURL;
              document.getElementById("aBase64Url").style = "display: block";
              document.getElementById("base64Url").src = imageURL;
              document.getElementById("aBase64Url").click();
              break;
            case "jpg":
              // document.body.appendChild(canvas)
              imageURL = canvas.toDataURL("image/jpeg"); //canvas转base64图片
              // 为 a 标签添加 href 属性，直接点击下载图片
              document.getElementById("aBase64Url").href = imageURL;
              document.getElementById("aBase64Url").style = "display: block";
              document.getElementById("base64Url").src = imageURL;
              document.getElementById("aBase64Url").click();
              break;
          }
        })
        .finally(() => {
          this.exportLoading = false;
          this.$message.success("导出成功！");
          this.dialogCloseHandler();
          this.exportEnd(scrollMap);
        });
    },
  },
};
</script>

<style lang="less">
.topo-download-dialog {
}
.topo-select-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
  .single-select {
    padding: 0 10px;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    height: 42px;
    width: 160px;
    border: 1px solid #d1d5dd;
    cursor: pointer;
    border-radius: 4px;
    font-size: 16px;
    font-weight: bold;
    color: #152c5b;
    .left-part {
      display: flex;
      align-items: center;
      .select-label {
        font-weight: bold;
        margin-left: 8px;
      }
    }
    .right-circle {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      border: 1px solid #b5bece;
    }
    .el-icon-success {
      color: #2a61ff;
      font-size: 10px;
    }
  }
  .single-select-active {
    border: 1px solid #2a61ff;
  }
}
</style>